


















































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
@Component({
    components: {
        LsDialog,
        LsPagination
    }
})
export default class OrderPane extends Vue {
    @Prop() value: any
    @Prop() pager!: any

    // 跳转
    click(event: any) {
        this.$router.push({
            path: '/order/after_sales_detail',
            query: { id: event.id }
        })
    }

    toUser(id: any) {
        this.$router.push({
            path: '/user/user_details',
            query: { id: id }
        })
    }
}
